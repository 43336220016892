import React, { Component } from "react";
import "./css/App.css";
import Container from "react-bootstrap/Container";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";

class Confirmation extends Component {

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />

        <Menu />
        <Container className="changeMajorForm">
          <div className="pageTitle">
            Your Concurrent Major Request Form has been submitted.
          </div>
        </Container>

        <Container className="changeMajorForm center">
          <br />
          <p>
            You will receive a confirmation email with your case number shortly.
            Once you do, you will be able to track this case in the{" "}
            <a
              href="https://webapp4.asu.edu/myasu/student/servicecenter"
              target="_blank" rel="noopener noreferrer" 
            >
              Service Center
            </a>{" "}
            on My ASU.
          </p>

          <p>
          Note: Your current major <i><strong>and</strong></i> your requested additional major must both approve your request to pursue concurrent degrees, 
          and you may be asked to supply additional information to support your request.
          </p>

          <p>
          The timeframe for reviewing these requests can vary depending on the College/School of the major.
          </p>

          <br />
          <br />
          <br />
          <br />
        </Container>

        <Footer />
      </Container>
    );
  }
}

export default Confirmation;
