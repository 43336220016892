import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
// import '../asuthemes/4.8/css/asu_header.css'
// import '../asuthemes/4.8/css/asu_header_media.css'
// import Button from 'react-bootstrap/Button'
// import '../asuthemes/4.8/js/asu_header.js'
//import '../asuthemes/4.8/vendor/cookieconsent/cookieconsent.min'

class Footer extends Component {



    render() {
        return (<Container className='' fluid>
            <div id="innovation-footer">
                <div id="innovation-bar">
                    <div className="innovation-top">
                        <div className="innovation-status">
                            <a href="https://www.asu.edu/rankings">
                                <span>ASU is #1 in the U.S. for Innovation</span></a>
                        </div>
                        <div className="innovation-hidden">
                            <a href="https://www.asu.edu/rankings">
                                <img src="https://www.asu.edu/asuthemes/4.9/assets/ASU-number-1-innovation-best-colleges.png" alt="Best Colleges U.S. News Most Innovative 2020"/>
                            </a>
                        </div>
                        </div>
                    </div>
                    <div className="footer-menu">
                        <ul className="default">
                            <li><a href="https://www.asu.edu/copyright/">Copyright and Trademark</a></li>
                            <li><a href="https://www.asu.edu/accessibility/">Accessibility</a></li>
                            <li><a href="https://www.asu.edu/privacy/">Privacy</a></li>
                            <li><a href="https://www.asu.edu/tou/">Terms of Use</a></li>
                            <li><a href="https://www.asu.edu/asujobs">Jobs</a></li>
                            <li><a href="https://www.asu.edu/emergency/">Emergency</a></li>
                            <li className="no-border"><a href="https://www.asu.edu/contactasu/">Contact ASU</a></li>
                        </ul>
                    </div>
                </div>

        </Container>)
        
        }
    
    }
    
export default Footer