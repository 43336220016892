import React, { Component } from "react";
import "./css/App.css";
import Container from "react-bootstrap/Container";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";

class Confirmation extends Component {

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />

        <Menu />
        <Container className="changeMajorForm">
          <div className="pageTitle">
          Your Certificate Change Request Form has been submitted.
          </div>
        </Container>

        <Container className="changeMajorForm center">
          <br />
          <p>
          You will receive a confirmation email with your case number shortly.  You will be contacted by an advisor within two business days, who may want to schedule an advising appointment.
          </p>

          <p>
          To track and make updates to your case, navigate to the Help tab on <a href="https://webapp4.asu.edu/myasu">My ASU</a>
          </p>

          <br />
          <br />
          <br />
          <br />
        </Container>

        <Footer />
      </Container>
    );
  }
}

export default Confirmation;
