import React, { Component } from "react";
import "./css/App.css";

import Container from "react-bootstrap/Container";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";

class IneligibleOther extends Component {

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />

        <Menu />
        <Container className="changeMajorForm">
          <div className="pageTitle">
            We are unable to process your request using this form
          </div>
        </Container>

        <Container className="changeMajorForm">
          <br />
          <p>
          Please be advised that only degree-seeking, undergraduate students who are enrolled for classes can use this form. If you believe you should have access to it, please contact your academic advisor.
          </p>
          <br />
          <br />
          <br />
        </Container>

        <Footer />
      </Container>
    );
  }
}

export default IneligibleOther;
