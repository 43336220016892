import sjcl from "sjcl";
import $ from "jquery";
import { properties } from "../properties.js";


var serviceauthId = properties.serviceauthId;
var serviceauthSecret = "serviceauth-public-agent";
var serviceauthRedirectUri = properties.domain;

var serviceauthOauthUrl =
  "https://weblogin.asu.edu/serviceauth/oauth2/native/allow";
var serviceauthTokenUrl =
  "https://weblogin.asu.edu/serviceauth/oauth2/native/token";
var scopes = [
  "https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self",
  "https://api.myasuplat-dpl.asu.edu/scopes/person/read:self",
  "https://api.myasuplat-dpl.asu.edu/scopes/student-group-membership/read:self",
  "https://changemajor.apps.asu.edu/api",
];

var DPL_BASE_URL = "https://api.myasuplat-dpl.asu.edu/api";

// TODO:
// var LOGOUT_URL = "https://weblogin.asu.edu/cas/logout";
var SS_SA_CODE_VERIFIER = "changemajor.serviceauth.codeVerifier";
var SS_SA_STATE = "changemajor.serviceauth.state";

var SS_JWT_TOKEN = "changemajor.jwt.token";
var SS_NAME = "changemajor.ss.name";
var SS_ASURITE = "changemajor.ss.asurite";
var SS_VIEW_AS = "changemajor.ss.viewas";

/*
	TODO:
   A browser-based application that wishes to use either long-lived
   refresh tokens or privileged scopes SHOULD restrict its JavaScript
   execution to a set of statically hosted scripts via a Content
   Security Policy ([CSP2]) or similar mechanism.  A strong Content
   Security Policy can limit the potential attack vectors for malicious
   JavaScript to be executed on the page.
 */

function generateCodeVerifier() {
  var alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";
  var length = 128;
  return generateRandomString(alphabet, length);
}


function generateRandomServiceauthState(queryString) {
  var alphabet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.";
  var length = 64;
  var ret = generateRandomString(alphabet, length);

  if(window.location.search!=='')
    queryString=window.location.search;

  var jsonObj = {"alphabet":ret,"queryString":queryString};

  return JSON.stringify(jsonObj);

}

function redirectToServiceauth(queryString) {
  var codeVerifier = generateCodeVerifier();
  var codeChallenge = sha256base64url(codeVerifier);
  var state = generateRandomServiceauthState(queryString);

  var scopeParam = "";
  for (var i = 0; i < scopes.length; i++) {
    if (scopeParam.length > 0) {
      scopeParam += " ";
    }
    scopeParam += scopes[i];
  }

  sessionStorage.setItem(SS_SA_CODE_VERIFIER, codeVerifier);
  sessionStorage.setItem(SS_SA_STATE, state);

  var url = serviceauthOauthUrl;
  url += "?response_type=code";
  url += "&client_id=" + encodeURIComponent(serviceauthId);
  url += "&redirect_uri=" + encodeURIComponent(serviceauthRedirectUri);
  url += "&state=" + encodeURIComponent(state);
  url += "&code_challenge_method=S256";
  url += "&code_challenge=" + codeChallenge;
  url += "&scope=" + encodeURIComponent(scopeParam);
  console.log("redirecting to oauth server at url=[" + url + "]");
  window.location.replace(url);
}

function handleOauthCode(code, state, success, err) {

  var storedState = sessionStorage.getItem(SS_SA_STATE);
  var codeVerifier = sessionStorage.getItem(SS_SA_CODE_VERIFIER);
  sessionStorage.removeItem(SS_SA_STATE);
  sessionStorage.removeItem(SS_SA_CODE_VERIFIER);

  var stateObj = JSON.parse(state);
  var alphabet = stateObj["alphabet"];
  var queryString = stateObj["queryString"];
  
  // alert("recevied state=[" + state + "]");
  // alert("stored state  =[" + storedState + "]");

  if(storedState===null || JSON.parse(storedState)["alphabet"]!==alphabet || JSON.parse(storedState)["alphabet"].length<1){
    console.log("recevied state=[" + state + "]");
    console.log("stored state  =[" + storedState + "]");
     err(
        "received unexpected state [" +
          state +
          "] - expected [" +
          storedState +
          "]"
      );
      return;
   
  }

  //console.log("codeVerifier  =["+codeVerifier+"]");
  var requestData = {
    grant_type: "authorization_code",
    code: code,
    redirect_uri: serviceauthRedirectUri,
    client_id: serviceauthId,
    client_secret: serviceauthSecret,
    code_verifier: codeVerifier,
  };

  //console.log("token request data: [" + JSON.stringify(requestData) + "]");
  $.ajax({
    type: "POST",
    url: serviceauthTokenUrl,
    cache: false,
    data: requestData,
    success: function (data) {
      //console.log("token endpoint success! data=[" + JSON.stringify(data));
      sessionStorage.setItem(SS_JWT_TOKEN, data.access_token);
      sessionStorage.setItem("RELOAD", true);
      
      if(queryString){
        sessionStorage.setItem("RELOAD_URL",window.location.href + queryString);
      }
      else
        sessionStorage.setItem("RELOAD_URL",window.location.href);

      // TODO: save token expiration time and refresh token
      // TODO: verify we got scopes we need

      success();
    },
    error: function (xhr, status, error) {
      err(
        "error: xhr: [" +
          JSON.stringify(xhr) +
          "] status=[" +
          status +
          "], error=[" +
          error +
          "]"
      );
    },
  });
}

function reauth(queryString) {
  console.log("reauthing...");
  // TODO: detect fail loop? throttle?
  sessionStorage.removeItem(SS_JWT_TOKEN);
  sessionStorage.removeItem(SS_NAME);
  sessionStorage.removeItem(SS_ASURITE);
  sessionStorage.removeItem(SS_VIEW_AS);
  sessionStorage.removeItem("RELOAD");
  sessionStorage.removeItem("RELOAD_URL");
  redirectToServiceauth(queryString);
}

// function deauth() {
//   sessionStorage.removeItem(SS_JWT_TOKEN);
//   sessionStorage.removeItem(SS_NAME);
//   sessionStorage.removeItem(SS_ASURITE);
//   sessionStorage.removeItem(SS_VIEW_AS);
//   sessionStorage.removeItem("RELOAD");
//   sessionStorage.removeItem("RELOAD_URL");
//   window.location.href = LOGOUT_URL;
// }

function getAccessToken() {
  return sessionStorage.getItem(SS_JWT_TOKEN);
}

function ajaxBeforeSendFunc(xhr) {
  xhr.setRequestHeader("Authorization", "Bearer " + getAccessToken());
}

function handleAuth(authComplete) {
  console.log("handleauth.. code=" + getParam("code"));
  if (getParam("code")) {
    var code = getParam("code");
    var state = getParam("state");

    var stateObj = JSON.parse(state);
    var queryString = stateObj["queryString"];
    

    window.history.replaceState({}, document.title, serviceauthRedirectUri);

    handleOauthCode(
      code,
      state,
      function () {
        authComplete();
      },
      function (errTxt) {
        // TODO: UI
        console.log("ERROR: " + errTxt);
        reauth(queryString);
      }
    );
  } else if (sessionStorage.getItem(SS_JWT_TOKEN)) {
    authComplete();
  } else {
    redirectToServiceauth();
  }
}

function getParam(nameIn) {
  var qs = window.location.search.substring(1, window.location.search.length);
  if (qs.length !== 0) {
    qs = qs.replace(/\+/g, " ");
    var qsargs = qs.split("&");
    for (var i = 0; i < qsargs.length; i++) {
      var j = qsargs[i].indexOf("=");
      if (j === -1) {
        var name = qsargs[i];
        var value = "";
      } else {
        name = qsargs[i].substring(0, j);
        value = unescape(qsargs[i].substring(j + 1));
      }
      if (nameIn === name) {
        return value;
      }
    }
  }
  return null;
}

// function readCookie(name) {
//   var nameEQ = name + "=";
//   var ca = document.cookie.split(";");
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === " ") c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0)
//       return unescape(c.substring(nameEQ.length, c.length));
//   }
//   return null;
// }

// function sha256hex(message) {
//   return sjcl.codec.hex.fromBits(sjcl.hash.sha256.hash(message));
// }

// without padding
function sha256base64url(message) {
  return sjcl.codec.base64url.fromBits(sjcl.hash.sha256.hash(message));
}


function generateRandomString(alphabet, length) {
  var randArr = new Uint8Array(length);
  var ret = "";
  window.crypto.getRandomValues(randArr);
  for (var i = 0; i < randArr.length; i++) {
    var j = randArr[i] % 64;
    ret += alphabet.substring(j, j + 1);
  }
  return ret;
}


// var emplid;

// function handleStudentGroupData(data) {
//   $("#sg-table").append($("<tbody>"));
//   data.forEach(function (sg) {
//     var tr = $("<tr>");
//     tr.append($("<td>").text(sg.studentGroupCode));
//     tr.append($("<td>").text(sg.description));
//     tr.append($("<td>").text(sg.effectiveDate));
//     $("#sg-table").find("tbody").append(tr);
//   });
//   $("#sg-data").show();
//   $("#sg-loading").hide();
// }

function handlePersonData(data) {
  $("#loading").hide();

  // TODO: test for presence
  var name = data.names.PRI.givenName + " " + data.names.PRI.familyName;

  sessionStorage.setItem(SS_NAME, data.names.PRI.givenName);

  $("#name").text(name);

  $(".userinfo").show();
}

function handlePrincipalData(data) {
  // TODO: check to ensure this is primary principal
  console.log("handlePriciple data");

  sessionStorage.setItem(SS_ASURITE, data.principal);

  // emplid = data.owner.emplid;

  // console.log("myasurite=" + data.principal);
  console.log("reload=" + sessionStorage.getItem("RELOAD"));
  
  if (sessionStorage.getItem("RELOAD")) {
    console.log("redirect");
    sessionStorage.removeItem("RELOAD");
    if(sessionStorage.getItem("RELOAD_URL")){
      var reload_url = sessionStorage.getItem("RELOAD_URL");
      sessionStorage.removeItem("RELOAD_URL");
      window.location.href = reload_url;
    }
    else
      window.location.reload();
  }

  $.ajax({
    type: "GET",
    url: DPL_BASE_URL + "/person/" + data.owner.emplid,
    beforeSend: ajaxBeforeSendFunc,
    cache: false,
    dataType: "json",
    success: function (data) {
      //console.log("person success! data=[" + JSON.stringify(data) + "]");
      handlePersonData(data);
    },
    error: function (xhr, status, error) {
      console.log(
        "person error: xhr: [" +
          JSON.stringify(xhr) +
          "] status=[" +
          status +
          "], error=[" +
          error +
          "]"
      );
      if (xhr.status === 401) {
        reauth();
      }
    },
  });
}

// function getStudentGroupData() {
//   $("#get-sg-button").hide();
//   $("#sg-loading").show();

//   $.ajax({
//     type: "GET",
//     url: DPL_BASE_URL + "/student-group-membership/emplid/" + emplid,
//     beforeSend: ajaxBeforeSendFunc,
//     cache: false,
//     dataType: "json",
//     success: function (data) {
//       console.log(
//         "student-group-membership success! data=[" + JSON.stringify(data) + "]"
//       );
//       handleStudentGroupData(data);
//     },
//     error: function (xhr, status, error) {
//       console.log(
//         "student-group-membership error: xhr: [" +
//           JSON.stringify(xhr) +
//           "] status=[" +
//           status +
//           "], error=[" +
//           error +
//           "]"
//       );
//       if (xhr.status === 401) {
//         reauth();
//       }
//     },
//   });
// }

function pageInit() {
  console.log("auth done; page is initializing.......");

// homepage with page=minors be redirect to the minors page after serviceauth.
  if(window.location.search==='?page=minors' && window.location.href.indexOf("/minors?page=minors")===-1){
    window.location.href="/minors?page=minors";
  }

  if(window.location.search==='?page=certs' && window.location.href.indexOf("/certs?page=certs")===-1){
    window.location.href="/certs?page=certs";
  }

  if(window.location.search==='?page=concurrent' && window.location.href.indexOf("/concurrent?page=concurrent")===-1){
    window.location.href="/concurrent?page=concurrent";
  }

  $(document).ready(function () {
    // $("#logout-button").click(deauth);

    // $("#get-sg-button").click(getStudentGroupData);
    // console.log("dpl_url = " + DPL_BASE_URL + "/principal/me");
    $.ajax({
      type: "GET",
      url: DPL_BASE_URL + "/principal/me",
      beforeSend: ajaxBeforeSendFunc,
      cache: false,
      dataType: "json",
      success: function (data) {
        console.log("principal success! data=[" + JSON.stringify(data) + "]");
        handlePrincipalData(data);
      },
      error: function (xhr, status, error) {
        console.log(
          "principal error: xhr: [" +
            JSON.stringify(xhr) +
            "] status=[" +
            status +
            "], error=[" +
            error +
            "]"
        );
        if (xhr.status === 401) {
          reauth();
        }
      },
    });
  });
}

handleAuth(pageInit);

const serviceauth = {
  serviceauthRedirectUri: serviceauthRedirectUri,
  SS_JWT_TOKEN: SS_JWT_TOKEN,
  SS_NAME: SS_NAME,
  SS_SA_STATE: SS_SA_STATE,
  SS_ASURITE: SS_ASURITE,
  SS_VIEW_AS: SS_VIEW_AS,
  SS_SA_CODE_VERIFIER: SS_SA_CODE_VERIFIER,
  serviceauthTokenUrl: serviceauthTokenUrl,
  serviceauthId: serviceauthId,
  serviceauthSecret: serviceauthSecret,
  DPL_BASE_URL: DPL_BASE_URL,
};

export default serviceauth;
