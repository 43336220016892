import React, { Component } from "react";
import "./css/App.css";

import Container from "react-bootstrap/Container";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import queryString from "query-string";

class Ineligible extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          certUrl:""
        };

        
      }

  componentDidMount() {
    var values = queryString.parse(this.props.location.search);
    var acadPlan = values.acadplan;
    var certUrl= "https://degrees.apps.asu.edu/minors/major/ASU00/" + acadPlan;
    this.setState({certUrl});
    console.log("certUrl: " + certUrl);
  }

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />

        <Menu />

        <Container className="changeMajorForm">
          <br />
          <p>
          Your request cannot be processed because you already have a pending request to add this certificate. Certificate requests are generally processed within 7 business days.
If it has been longer than 7 business days, or you have questions regarding your certificate request, please <a href={this.state.certUrl}>contact the department</a> offering the certificate for assistance.

          </p>
          <br />
          <br />
          <br />
        </Container>

        <Footer />
      </Container>
    );
  }
}

export default Ineligible;
