export const properties = {
  environment: "prod",
  serviceauthId: "changemajor-app",
  domain: "https://changemajor.apps.asu.edu",
  endpoint : "https://h8wh069y21.execute-api.us-west-2.amazonaws.com/prod/resources",
  degreesearchUrl: "https://webapp4.asu.edu/programs",
  wpcareyUrl: "https://sos.wpcarey.asu.edu/forms/changemajor",
  wpcareyMinorsUrl: "https://apps.wpcarey.asu.edu/apps/advising/modules/minor/index.cfm",
  wpcareyCertsUrl: "https://apps.wpcarey.asu.edu/apps/advising/modules/certificates/index.cfm"
};
