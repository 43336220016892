import React, { Component } from "react";

class Masquerade extends Component {

  render() {
    return (
      <div>
        <h2>Masquerade</h2>

        <form onSubmit={this.props.handleSubmitMasquerade}>
          <label>
            Enter asurite:
            <input
              type="text"
              name="asurite"
              value={this.props.asurite}
              onChange={this.props.handleChange}
            />
          </label>
          <input type="submit" value="Submit" />
        </form>

        <ul>
          <li>rbencom2</li>
          <li>smcrowe</li>
        </ul>
      </div>
    );
  }
}

export default Masquerade;
