import React, { Component } from "react";
import "../css/Menu.css";

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
    };
  }

  render() {
    console.log(`${this.state.page}`);
    return (
      <React.Fragment>
        <div className="navbar" id="asu_hdr_title_gen">
          {/* <div>
            <a title="Home" rel="home" href="/" id="asu_hdr_title">
              <span className="asu-site-title">
                Undergraduate Change of Major 
              </span>
            </a>
          </div> */}
        </div>

        {/* <Navbar bg="dark" variant="dark" className="asu-nav">
                             
                            <Container>
                            <Nav className="mr-auto">
                            
                                <Nav.Item href="/catalog/classes" as="a" className="pl-2 pr-2 mr-1"><FontAwesomeIcon icon={faHome} /></Nav.Item>
                                <Nav.Item href="/catalog/classes" as="a" className={`pl-2 pr-1 mr-1 ${this.state.page === "classes" ? "active" : "" }`}>Class Search</Nav.Item>
                                <Nav.Item href="/catalog/courses" className={`pl-2 pr-2 ${this.state.page === "courses" ? "active" : "" }`} as="a" >Course Catalog Search</Nav.Item> 
                                {sessionStorage.getItem(serviceauth.SS_NAME) && sessionStorage.getItem(serviceauth.SS_JWT_TOKEN) &&
                                <Nav.Item href="https://webapp4-qa.asu.edu/catalog/Scheduler.html?=" className='pl-2 pr-2' as="a" >Schedule Planner</Nav.Item>}
                            </Nav>
                            </Container>
                        </Navbar>  */}
      </React.Fragment>
    );
  }
}

export default Menu;
