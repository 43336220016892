import React, { Component } from "react";
import "./css/App.css";
import Select from "react-select";
import Container from "react-bootstrap/Container";
import StaticData from "./StaticData";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactHtmlParser from "react-html-parser";
import queryString from "query-string";
import $ from "jquery";
import serviceauth from "./auth/serviceauth";
import { properties } from "./properties.js";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from 'react-helmet';


class RequestForm extends Component { 

  endpoint = properties.endpoint;
  environment = properties.environment;

    
  initialState = {
    new_subplan: null,
    new_campus: null,
    contact_phone: "",
    why: null,
    additional_info: "",
    objective_info: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      asurite:
        sessionStorage.getItem(serviceauth.SS_VIEW_AS) != null
          ? sessionStorage.getItem(serviceauth.SS_VIEW_AS)
          : sessionStorage.getItem(serviceauth.SS_ASURITE),
      admin:
          this.environment !== 'prod'
            ? StaticData.adminList
            : [],
      term:"",
      emplid: "",
      first_name: "",
      last_name: "",
      student_type: "Ground",

      new_major: null,
      new_major_title: "Which major would you like to add?",

      new_subplan: null,
      new_subplan_title: "Which subplan would you like to choose?",

      new_campus: null,
      new_campus_title: "Which campus do you want to attend?",

      requirements_check: "Yes",
      requirements_check_title: "Do you currently meet these requirements?",

      hours_check: "Yes",
      hours_check_title:"Do you anticipate requesting to take more than 18 hours (19 hours for current Fulton Schools of Engineering majors) per semester to complete your concurrent degrees?",
      hours_check_acknowledge_yes: null,
      hours_check_acknowledge:"I understand that I will need to submit an overload petition to take more than 18 hours per semester and not all requests are approved.",
      


      double_check: "I am sure",
      double_check_title:
        "How sure are you that you would like to add this concurrent major?",

      contact_phone: "",
      contact_phone_title: "At which phone number can you be reached?",

      why: null,
      why_title: "Why do you want to add this concurrent major?",

      acknowledge_check: new Set(),
      acknowledge_check_title: "Read these statements and acknowledge:",

      objective_info: "",
      objective_info_title: "Please briefly explain your short-term and longer-term personal and career objectives related to this request.",

      additional_info: "",
      additional_info_title: "Is there any other additional information that you would like us to know?",


      confirmation: "",

      studentMajors: [], // student current majors from Mulesoft API
      student_first_major_plancode:"", //student's major plancode (first major if has multiple)
      student_first_major_subplan_code:"",
      student_first_major_campus:"",
    //   student_first_major_wp_carey:"",
    //   all_undergrad_majors: [], // all undergrad majors
      hits: [], // all minors list from Degree search elasticsearch
      ds_major_list: null, // major dropdown
      campus_list: [{ value: null, label: "No campuses to display" }], // campus dropdown when a major is choosen
      subplans: null, // subplan dropdownlist

      new_major_error: "",
      new_subplan_error: "",
      new_campus_error: "",
      requirements_check_error: "",
      hours_check_acknowledge_error:"",
      double_check_error: "",
      contact_phone_error: "",
      why_error: "",
      acknowledge_check_error:"",
      objective_info_error:"",
      error_message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    
  }

  clearProgressSessionStorage(){
    sessionStorage.removeItem("progress");
    sessionStorage.removeItem("storedAsurite");
    sessionStorage.removeItem("storedEmplid")
    sessionStorage.removeItem("emailBody");
    sessionStorage.removeItem("emailBodyJson");
    sessionStorage.removeItem("plancode");
    sessionStorage.removeItem("subplan");
    sessionStorage.removeItem("campus");
    sessionStorage.removeItem("storedToken");
  }
  getNextTerm(){
    var date = new Date();
    var month = date.getMonth();
    var year= date.getFullYear().toString().substr(2,2);
    var nextYear = (date.getFullYear()+1).toString().substr(2,2);
    
    if(month>=0 && month<=6){
      this.setState({term: "2" + year + "7"});
    }
    else
      this.setState({term: "2" + nextYear + "1"});
  }


  //===============GET STUDENT DATA FROM MULESOFT API ========

  async getStudentMajor(acadPlan) {

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "student-major",
        asurite: this.state.asurite,
      }),
    };
    console.log("get studenmajor for ausrite: " + this.state.asurite);
    

    if (this.state.asurite) {
      await fetch(this.endpoint, requestOptions)
        .then((response) => response.json())
        .then((data) => {
        //   console.log("student:" + JSON.stringify(data.studentMajor)); 
          
          if (data.studentMajor) {
            $(".loadWait").hide();
            $(".submitWait").hide();
            $(".resubmitWait").hide();

            var show_form = false;

            data.studentMajor.forEach(studentMajor => {
              this.setState({ emplid: studentMajor.emplid });
              this.setState({ first_name: studentMajor.firstName });
              this.setState({ last_name: studentMajor.lastName });
              var careers = studentMajor.careers;          

              if (careers) {
                careers.forEach(careerr => {
                  if(careerr.career && careerr.career === "UGRD"){
                    var career = careerr.career;
                    var plans = careerr.plans;
                    this.setState({ career: career });
                    this.setState({ studentMajors: plans });
                    
                    if (plans) {
                      plans.forEach(plan => {
                        if (
                          plan.degree !=="AA" && plan.degree !=="AS" &&//block Military studies and Associates in Sciences student from access any form.
                          plan.planType === "MAJ" && plan.planCode !=='BALGMARAS' && plan.planCode !=='LSORGLAA' && plan.planCode !=='LSMILSTAA'
                        ) {
                            this.setState({student_first_major_plancode:plan.planCode});
                            this.setState({student_first_major_campus:plan.campus});
                            var subplans=plan.subplans;
                            if(subplans){
                                subplans.forEach(subplan => {
                                    this.setState({student_first_major_subplan_code:subplan.subplan});
                                });
                            }
                            var student_type="Ground";
                            if(plan.campus==='ONLNE'){
                                student_type="Online";
                                this.setState({
                                    student_type:"Online"
                                  });
                            }
                            this.getConcurrentElasticSearch(student_type,acadPlan);
                            show_form=true;
                        }
                      });
                    } 
                  } 
                });   
              } 
            });

            if (show_form === false) {
              window.location.href = "/notice";
            }

          } else {
            if(JSON.stringify(data).includes("enrolled")){
              window.location.href = "/ineligibleOther";
            }
            else
              window.location.href = "/notice";
          }
        })
        .catch((error) => console.log("Error student major! " + error.message));
    }
  }


  // =============== GET PLAN DATA FROM ELASTICSEARCH API =========
  async getConcurrentElasticSearch(student_type,acadPlan) {
    
    console.log("environment = " + this.environment);
    console.log("next term: " + this.state.term);
    console.log("acadplan: " + acadPlan);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        api: "elasticsearch",
        elasticIndex:"program-undergrad-false",
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        outputParams: [
          "DEGREE",
          "ACADPLAN",
          "CAMPUSDESCR",
          "MAJORDESCRIPTION",
          "CHANGEMAJOR",
          "MAJORMAPSUBPLANS",
          "COLLEGEURLANDDESCR",
          "MAJORDESCRLONG",
          "CAMPUSLIST",
          "ACADPLANTYPE"
          // "DARSAUDIT",
        ],
        matchQuery: this.state.term,
        matchFields: ["APPLYDEADLINES.KEY"],
      }),
    };

    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data=" + JSON.stringify(data));

        data.sort(function (a, b) {
          a = a._source.MAJORDESCRIPTION.toLowerCase();
          b = b._source.MAJORDESCRIPTION.toLowerCase();

          return a < b ? -1 : a > b ? 1 : 0;
        });

        this.setState({ hits: data });
        var majorList = [];
        data.forEach(hit => {
          var major = {
            value: hit._source.ACADPLAN,
            label:
              hit._source.MAJORDESCRIPTION +
              ", " +
              hit._source.DEGREE +
              " [" +
              hit._source.ACADPLAN +
              "]",
          };
          
            if(hit._source.ACADPLANTYPE === 'MAJ' && ((student_type === 'Online' && hit._source.CAMPUSLIST.indexOf('Online')!==-1) || (student_type==="Ground" && hit._source.CAMPUSLIST.trim()!=='Online'))){            
                // console.log("major=" + major);
                if (hit._source.ACADPLAN === acadPlan) {
                  this.setState({ new_major: major });
                }
                if (major.value !== "LALAWPRE" && major.value !== "9HOHONALL" && major.value !=='LSMILSTAA' && major.value !=='BALGMARAS' && major.value !=='LSORGLAA') {
                  majorList.push(major);
                }
              }
            
        });
        this.setState({ ds_major_list: majorList });
        if (acadPlan) {
          this.getLocationsByAcadPlan(data, acadPlan,student_type);
          sessionStorage.removeItem("queryStringAcadplan");
        }
      })
      .catch((error) => console.log("Error elastic! " + error.message));
  }


//===========GET LOCATION BY PLAN CODE FROM PLAN DATA (FROM ELASTICSEARCH)========================

  getLocationsByAcadPlan(hits, acadPlan,student_type) {
    // console.log("getLocation plan=" + acadPlan);
    hits.forEach(hit => {
      if (hit._source.ACADPLAN === acadPlan) {
        var campus_list = [];

        if (hit._source.CAMPUSDESCR) {
          
          hit._source.CAMPUSDESCR.forEach(campus => {
              if((student_type==='Online' && campus.VALUE==='Online') || (student_type==='Ground' && campus.VALUE!=='Online')){    
                var campu = { value: campus.KEY, label: campus.VALUE };
                    campus_list.push(campu);
              }
            
          });
          
          this.setState({ campus_list });
        } else {
          this.setState({ campus_list: null });
        }

        // this.setState({ new_major_college: hit._source.COLLEGEURLANDDESCR });


        this.setState(this.initialState);
        this.setState({ subplans: null });

        if(campus_list.length===1){
            this.setState({new_campus:campus_list[0]});
            var mmSubplanCampus = "ALL";
              if(campus_list[0]==="ONLNE"){
                mmSubplanCampus="ONLINE";
              }
              this.getSubplanByAcadPlanCampus(hits, acadPlan,mmSubplanCampus);
          }
      }
    });
  }

  getSubplanByAcadPlanCampus(hits, acadPlan,mmSubplanCampus) {
    console.log("mmSubplancampus : " + mmSubplanCampus);
    console.log("getLocation plan=" + acadPlan);
    hits.forEach(hit => {
      if (hit._source.ACADPLAN === acadPlan) {
        
        if (mmSubplanCampus && hit._source.MAJORMAPSUBPLANS){
          console.log("get subplan info");
          var subplans = [];
          hit._source.MAJORMAPSUBPLANS.forEach(subplan => {
            var subplanKeyArr = subplan.KEY.split(":");
            console.log("subplanKeyArr: " + subplan.KEY);
            var subplanKey= subplanKeyArr[0];
            var subplanCampus = subplanKeyArr[1];
            if(subplanCampus ){
              if(subplanCampus===mmSubplanCampus){
                var subpln = { value: subplanKey, label: subplan.VALUE };
                subplans.push(subpln);
              }
            }
            else{
                subpln = { value: subplan.KEY, label: subplan.VALUE };
                subplans.push(subpln);
            }
 
          });

          if(subplans.length>0)
            this.setState({ subplans });
          else {
            this.setState({ subplans: null});
          }

          if(subplans.length===1){
            this.setState({new_subplan:subplans[0]});
          }
          else{
            this.setState({new_subplan:null});
          }

        }
      }
    });
  }

//=================GET EMAIL BODY AND PASS TO SEND_EMAIL ==========================

  getEmailBody() {

    var notes = "FOR ADVISOR/ADMIN USER ONLY\nPost a comment in the Case Feed to indicate the student's Requirement term for their added concurrent major. (Ex: \"Requirement Term: Spring 2022\")";
    var student_info_title = "Request from: ";
    var student_info_name = this.state.first_name + " " + this.state.last_name;
    var student_info_emplid = this.state.emplid;

    var student_majors_title = "Student current majors: ";
    var studentMajors = "";
    this.state.studentMajors.forEach (function(value) {
      studentMajors += "\n" + value.transcriptDescription + ", " + value.degree;
      })

    var new_major_title = this.state.new_major_title;
    var new_major_text = this.state.new_major.label;

    var new_campus_title = this.state.new_campus_title;
    var new_campus_text = this.state.new_campus.label;

    if (
        this.state.new_subplan &&
        this.state.new_campus
      ) {
        var new_subplan_title = this.state.new_subplan_title;
        var new_subplan_text = this.state.new_subplan.label;
      }

    var requirements_check_title = this.state.requirements_check_title;
    var requirements_check = this.state.requirements_check;
    var hours_check_title = this.state.hours_check_title;
    var hours_check = this.state.hours_check;
   
    var double_check_title = this.state.double_check_title;
    var double_check = this.state.double_check;

    var contact_phone = this.state.contact_phone;
    var contact_phone_title = this.state.contact_phone_title;
    var why_title = this.state.why_title;
    var why = this.state.why.label;
    var objective_info = this.state.objective_info;
    var objective_info_title = this.state.objective_info_title;
    var additional_info = this.state.additional_info;
    var additional_info_title = this.state.additional_info_title;

    var acknowledge_check_title = this.state.acknowledge_check_title;
    var acknowledgeText = "";
    this.state.acknowledge_check.forEach (function(value) {
        acknowledgeText += "\n" + value;
        })

    var hours_check_acknowledge = "";
    if(this.state.hours_check_acknowledge_yes){
      hours_check_acknowledge = this.state.hours_check_acknowledge;
      }

    var bodyText =
      "\n" + 
      notes + 
      "\n\n" +
      student_info_title +
      "\n" +
      student_info_name +
      "(" +
      student_info_emplid +
      ")" +
      "\n\n";
    
      bodyText +=
      student_majors_title +
      "\n" +
      studentMajors +
      "\n\n";
    
    bodyText +=
      new_major_title +
      "\n" +
      new_major_text +
      "\n\n";

    if (
    this.state.new_subplan &&
    this.state.new_campus
    ) {
    bodyText +=
        new_subplan_title +
        "\n" +
        new_subplan_text +
        "\n\n";
    }

    bodyText +=
      new_campus_title +
      "\n" +
      new_campus_text +
      "\n\n";

    bodyText +=
    
      requirements_check_title +
      "\n" +
      requirements_check +
      "\n\n";

    bodyText +=
      hours_check_title +
      "\n" +
      hours_check +
      "\n" +
      hours_check_acknowledge +
      "\n\n";

    bodyText +=
      double_check_title +
      "\n" +
      double_check +
      "\n\n";

    bodyText +=
     
      contact_phone_title +
      "\n" +
      contact_phone +
      "\n\n";

    bodyText +=
      why_title + "\n" + why + "\n\n";

    bodyText +=
      objective_info_title + "\n" + objective_info + "\n\n";

    bodyText +=
        acknowledge_check_title + "\n"  + acknowledgeText + "\n\n";

    bodyText +=
      additional_info_title +
      "\n" +
      additional_info +
      "\n\n";

    return bodyText;
  }

  getEmailBodyJson() {

    var student_info_emplid = this.state.emplid;

    // var major_to_change = this.state.major_to_change;

    var new_major_text = this.state.new_major.label;
    var new_campus_text = this.state.new_campus.label;

    if (
        this.state.new_subplan &&
        this.state.new_campus
      ) {
        var new_subplan_text = this.state.new_subplan.label;
      }

    var contact_phone = this.state.contact_phone;
    var double_check = this.state.double_check;
    var additional_info = this.state.additional_info;
    var objective_info = this.state.objective_info;
    var requirements_check = this.state.requirements_check;
    var hours_check = this.state.hours_check;
    var why = this.state.why.label;

    var acknowledgeText = [];
    this.state.acknowledge_check.forEach (function(value) {
        acknowledgeText.push(value);
        })
        
    var hours_check_acknowledge = "";
    if(this.state.hours_check_acknowledge_yes){
     hours_check_acknowledge = this.state.hours_check_acknowledge;
    }
    
    var studentMajors = [];

    this.state.studentMajors.forEach (function(value) {
        studentMajors.push(value.transcriptDescription + ", " + value.degree);
      })
    
    var json_arr={};
    json_arr["emplid"] = student_info_emplid;
    json_arr["first_name"] = this.state.first_name;
    json_arr["last_name"]= this.state.last_name;
    json_arr["student_majors"] = studentMajors;
    json_arr["new_concurrent"] = new_major_text;

    if (
      this.state.new_subplan &&
      this.state.new_campus
      ) {
        json_arr["new_subplan"] = new_subplan_text;
      }

    json_arr["new_campus"] = new_campus_text;
    json_arr["requirements_check"] = requirements_check;
    json_arr["hours_check"] = hours_check;
    json_arr["hours_check_acknowledge"] = hours_check_acknowledge;
    json_arr["double_check"] = double_check;
    json_arr["contact_phone"] = contact_phone;
    json_arr["why"] = why;
    json_arr["objective_info"] = objective_info;
    json_arr["acknowledge"] = acknowledgeText;
    json_arr["additional_info"] = additional_info;
    json_arr["form_type"] = "concurrent";
    var bodyTextJson = JSON.stringify(json_arr);

    return bodyTextJson;
  }


  //================== submit form to salesforce===============

  async sendSalesforce() {

    var bodyText = this.getEmailBody();
    var bodyTextJson = this.getEmailBodyJson();
    var emplid = this.state.emplid;
    
    console.log("emplid=" + emplid);

    var plancode = this.state.student_first_major_plancode;
    var campus = this.state.student_first_major_campus;
    var subplanCode = null;

    if(this.state.student_first_major_subplan_code)
      subplanCode=this.state.student_first_major_subplan_code;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/html",
      },
      body: JSON.stringify({
        authorizationToken: sessionStorage.getItem(serviceauth.SS_JWT_TOKEN),
        api: "salesforce-case",
        plancode: plancode,
        subplan: subplanCode,
        campus:campus,
        subject: "Add a Concurrent (Double) Major Request Form Submission",
        bodyText: bodyText,
        emplid:emplid,
        bodyTextJson: bodyTextJson,
      }),
    };


    await fetch(this.endpoint, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("salesforce return " + data);
        if(data==='401'){
          
          sessionStorage.setItem("progress","concurrent");
          sessionStorage.setItem("storedAsurite",this.state.asurite);
          sessionStorage.setItem("storedEmplid",this.state.emplid);
          sessionStorage.setItem("emailBody",bodyText);
          sessionStorage.setItem("emailBodyJson", bodyTextJson);
          sessionStorage.setItem("plancode",plancode);
          sessionStorage.setItem("subplan",subplanCode);
          sessionStorage.setItem("campus",campus);
          sessionStorage.setItem("storedToken",sessionStorage.getItem(serviceauth.SS_JWT_TOKEN));

         
          window.location.href = "/concurrent";
        }

        console.log("submit email:" + JSON.stringify(data.statusCode));
        if (data.statusCode===201  || data.statusCode === 200) {
            this.clearProgressSessionStorage();
            window.location.href = "/confirmationConcurrent";
        }
      })
      .catch((error) => console.log("Error! " + error.message));
  }

  async resendSalesforce() {
    
    var bodyText = sessionStorage.getItem("emailBody")
    var bodyTextJson = sessionStorage.getItem("emailBodyJson");
    var plancode = sessionStorage.getItem("plancode");
    var subplan = sessionStorage.getItem("subplan");
    var campus = sessionStorage.getItem("campus");
    var emplid = sessionStorage.getItem("storedEmplid");
    
    var token = sessionStorage.getItem(serviceauth.SS_JWT_TOKEN);
    var storedToken = sessionStorage.getItem("storedToken");

    var storedAsurite = sessionStorage.getItem("storedAsurite");

    // alert("token=" + token);
    // alert("storedToken = " + storedToken);

    if(token!=null && token!==storedToken){
      if(storedAsurite===this.state.asurite || this.state.admin.includes(sessionStorage.getItem(serviceauth.SS_ASURITE))){
        // alert("resend email");
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "text/html",
          },
          body: JSON.stringify({
            authorizationToken: token,
            api: "salesforce-case",
            plancode: plancode,
            subplan: subplan,
            campus:campus,
            subject: "Add a Concurrent (Double) Major Request Form Submission",
            bodyText: bodyText,
            emplid:emplid,
            bodyTextJson: bodyTextJson,
          }),
        };
    
    
        await fetch(this.endpoint, requestOptions)
          .then((response) => response.json())
          .then((data) => {
          
            this.clearProgressSessionStorage();

            if (data.statusCode=== 201  || data.statusCode === 200) {
              window.location.href = "/confirmationConcurrent";
            }
          })
          .catch((error) => console.log("Error! " + error.message));
      }
    }

  }


  validate = () => {
    let new_major_error;
    let new_campus_error;
    let new_subplan_error;
    let contact_phone_error;
    let why_error;
    let objective_info_error;
    let acknowledge_check_error;
    let hours_check_acknowledge_error;
    let error_message = "";

    if (!this.state.new_major) {
      new_major_error = "error";
      error_message +=
        "<li>" + this.state.new_major_title + " field is required</li>";
    }

    if (
        this.state.subplans &&
        this.state.new_campus
      ) {
        if (!this.state.new_subplan) {
          new_subplan_error = "error";
          error_message +=
            "<li>" + this.state.new_subplan_title + " field is required</li>";
        }
      }
   
    if (!this.state.new_campus || !this.state.new_campus.value) {
      new_campus_error = "error";
      error_message +=
        "<li>" + this.state.new_campus_title + " field is required</li>";
    }

      if (this.state.contact_phone === "") {
        contact_phone_error = "error";
        error_message +=
          "<li>" + this.state.contact_phone_title + " field is required</li>";
      }

      if (!this.state.why) {
        why_error = "error";
        error_message +=
          "<li>" + this.state.why_title + " field is required</li>";
      }

      if (this.state.objective_info === "") {
        objective_info_error = "error";
        error_message +=
          "<li>" + this.state.objective_info_title + " field is required</li>";
      }
      console.log("hours_check=" + this.state.hours_check);
      console.log("hours_check_acknowledge_yes=" + this.state.hours_check_acknowledge_yes);
      
      if(this.state.hours_check==="Yes"){
        if(this.state.hours_check_acknowledge_yes !== true ){
          hours_check_acknowledge_error="error";
          error_message +=
          "<li>" + this.state.hours_check_title + " field is required</li>";
        }
      }

      if (this.state.acknowledge_check.size !== 5) {
        acknowledge_check_error = "error";
        error_message +=
          "<li>" + this.state.acknowledge_check_title + " field is required</li>";
      }

    if (
      new_major_error ||
      new_campus_error ||
      new_subplan_error ||
      contact_phone_error ||
      why_error || 
      objective_info_error ||
      acknowledge_check_error ||
      hours_check_acknowledge_error
    ) {
      error_message = "<div><ul>" + error_message + "</ul></div>";
      this.setState({ error_message });
      this.setState({
        new_major_error,
        new_subplan_error,
        new_campus_error,
        contact_phone_error,
        why_error,
        objective_info_error,
        acknowledge_check_error,
        hours_check_acknowledge_error
      });

      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    
    const isValid = this.validate();
    if (isValid) {
      $(".submitWait").show();
      this.sendSalesforce();
    }
  };

  //===switch View-As === 
  
  handleSwitch() {
    sessionStorage.removeItem(serviceauth.SS_VIEW_AS);
    window.location.href = "/concurrent";
  }


  handleChangeSelect(name, value) {
    //var value = event.target.value;
    this.setState({ [name]: value });

    if (name === "new_major") {
      var hits = this.state.hits;
      var acadPlan = value.value;
      this.getLocationsByAcadPlan(hits, acadPlan,this.state.student_type);
      // this.getSalesforceEmail(acadPlan);
    }
  }

  handleChange(event, checkedItems) {
    const type = event.target.type;
    var name = event.target.name;
    var value = event.target.value;

    console.log("name=" + name + ",type=" + type + ",value=" + event.target.value + ",checkedItems = " + checkedItems);

    if (type === "radio" || type.indexOf("select") >= 0) {
      this.setState({ [name]: value }, () => {});

      if(name==="hours_check"){
        this.setState({ hours_check_acknowledge_yes: null });
      }

    } else if (type === "checkbox") {
        console.log("checkbox clicked");
      var val = [];
      if (checkedItems) {
        //multiple select
        checkedItems.forEach((value, key) => {
          if (value) {
            val.push(key);
          }
        });
      } else {
        //regular checkbox
        console.log("regular checkbox checked");
        val = event.target.checked;
      }
      this.setState(
        {
          [name]: val,
        },
        () => {
          console.log("after state update");
          // this.pushToHistory();
        }
      );
    } else {
      // console.log("hello" + type + event.target.value);
      this.setState({ [name]: event.target.value });
    }
  }

  handleCheck(event) {
    console.log(event.target.name + " " + event.target.value + " " + event.target.checked)
    const chkName = event.target.name
    const chkValue = event.target.value
    var selectedList = this.state[chkName]
    //if checkbox is checked
    if (event.target.checked) {
        selectedList.add(chkValue)
    } else {//if checkbox is NOT checked
        selectedList.delete(chkValue)
    }
    this.setState({
        [chkName]: selectedList
    })
}

  componentDidMount() {
    var progress = sessionStorage.getItem("progress");
    var emailBody = sessionStorage.getItem("emailBody");
    // console.log("progres=" + progress);
    // console.log("emailboyd=" + emailBody);
    // console.log("token=" + sessionStorage.getItem(serviceauth.SS_JWT_TOKEN));

    var values = queryString.parse(this.props.location.search);
    var acadPlan = values.acadplan;
    
    if(acadPlan){
      sessionStorage.setItem("queryStringAcadplan",acadPlan);
    }

    var pageUrl=window.location.href;

    if(pageUrl.indexOf("/concurrent?page=concurrent")===-1){
        window.location.href="/concurrent?page=concurrent";
    }
    
    if(progress==='concurrent' && emailBody !==null){
      // alert("resend salesforce for concurrent form");

      $(".loadWait").hide();
      $(".submitWait").hide();
      $(".resubmitWait").show();
      this.resendSalesforce();
    }
    else{
      this.clearProgressSessionStorage();

      this.getNextTerm();
      // get plancode from url if there is plancode
      var queryStringAcadplan = sessionStorage.getItem("queryStringAcadplan");
      console.log("querystring: " + queryStringAcadplan);
      this.getStudentMajor(queryStringAcadplan); // get student current majors and elasticsearch major list
      
    }  

  }



  render() {
    return (

      <Container fluid={true} className="p-0">
        <Header />
        <Menu />
        <Helmet>
        <title>Add a Concurrent Degree (Double Major) Request Form</title>
        </Helmet>

        {this.state.emplid ? (
          <div>
            <Container>
              {this.state.error_message ? (
                <div className="error_message">
                  {ReactHtmlParser(this.state.error_message)}
                </div>
              ) : null}

              <div className="pageTitle">Add a Concurrent Degree (Double Major) Request Form</div>
            </Container>
            <br/><br/>

            <Container className="changeMajorForm">
              <h2>
                {this.state.first_name} {this.state.last_name}
              </h2>
              {this.state.studentMajors.map((plan) => (
                <div>
                  {plan.transcriptDescription}, {plan.degree}
                </div>
              ))}
              <br />
              

              <div id="form_new_major" className={this.state.new_major_error}>
                <label
                  for="new_major"
                  className={this.state.new_major_error + "_label form-label"}
                >
                  {this.state.new_major_title} <span className="red"> * </span>
                </label>
                <a href="https://degrees.apps.asu.edu/bachelors" target="_blank" rel="noopener noreferrer"> Still not sure? Search all majors</a>
                <Select
                  id="new_major"
                  value={this.state.new_major}
                  defaultValue={this.state.new_major}
                  options={this.state.ds_major_list}
                  onChange={this.handleChangeSelect.bind(this, "new_major")}
                  className={this.state.new_major_error + "_select"}

                  //openMenuOnClick={false}
                />
              </div>

              <br/>
              <div id="form_new_campus" className={this.state.new_campus_error}>
                <label
                  for="new_campus"
                  className={this.state.new_campus_error + "_label form-label"}
                >
                  {this.state.new_campus_title} <span className="red"> *</span>
                </label>
                
                
                {this.state.new_campus ?(
                <Select
                  id="new_campus"
                  value={this.state.new_campus}
                  options={this.state.campus_list}
                  onChange={this.handleChangeSelect.bind(this, "new_campus")}
                  defaultValue={this.state.new_campus}
                  className={this.state.new_campus_error + "_select"}
                  isClearable={true}
                />    
                  ):(
                    <Select
                    id="new_campus"
                    value={this.state.new_campus}
                    options={this.state.campus_list}
                    onChange={this.handleChangeSelect.bind(this, "new_campus")}
                    
                    className={this.state.new_campus_error + "_select"}
                    isClearable={true}
                 />
                  )
                }

                
              </div>
             
              {this.state.subplans &&
              this.state.new_campus ? (
                <div id="form_subplan" className={this.state.new_subplan_error}>
                     <p></p>
                  <label
                    for="new_subplan"
                    className={
                      this.state.new_subplan_error + "_label form-label"
                    }
                  >
                    {this.state.new_subplan_title}{" "}
                    <span className="red"> *</span>
                  </label>
                  <Select
                    id="new_subplan"
                    value={this.state.new_subplan}
                    options={this.state.subplans}
                    onChange={this.handleChangeSelect.bind(this, "new_subplan")}
                    className={this.state.new_subplan_error + "_select"}
                    isClearable={true}
                  />
                   <p></p>
                </div>
              ) : (
                <p></p>
              )}
               
               <div>
                    <label class="form-label">
                    Concurrent Major requirements:
                    </label>
                    <div>
                    Your current major and your new major must approve your request to pursue a concurrent degree. Please read the academic policies to review admission criteria, policy for double-counting credits, and other requirements and rules that are defined by your current College/School 
                    and the one offering the degree program you are requesting to add. <a href="https://catalog.asu.edu/policies/college" target="_blank" rel="noopener noreferrer">https://catalog.asu.edu/policies/college</a>
                    </div>
                </div>

                <br/>
                <div>
                  <Form.Group>
                    <Form.Label>
                      {this.state.requirements_check_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>

                    <Form.Check
                      type="radio"
                      id="requirements_check_yes"
                      checked={this.state.requirements_check === "Yes"}
                      onChange={this.handleChange}
                      value="Yes"
                      name="requirements_check"
                      label="Yes"
                      className="circle"
                    />

                    <Form.Check
                      type="radio"
                      id="requirements_check_no"
                      name="requirements_check"
                      checked={this.state.requirements_check === "No"}
                      onChange={this.handleChange}
                      value="No"
                      label="No"
                      // onChange={this.handleChange}
                    />
                    <Form.Check
                      type="radio"
                      id="requirements_check_unknow"
                      name="requirements_check"
                      label="I don't know"
                      checked={this.state.requirements_check === "I don't know"}
                      onChange={this.handleChange}
                      value="I don't know"
                      // onChange={this.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className={this.state.hours_check_acknowledge_error}>
                  <Form.Label className={this.state.hours_check_acknowledge_error + "_label"}>
                    {this.state.hours_check_title}{" "}
                    <span className="red"> *</span>
                  </Form.Label>

                  <Form.Check
                    type="radio"
                    id="hours_check_yes"
                    checked={this.state.hours_check === "Yes"}
                    onChange={this.handleChange}
                    value="Yes"
                    name="hours_check"
                    label="Yes"
                    className="circle"
                  />
                    {this.state.hours_check==="Yes" ?(
                      <Form.Group className="form-group-hours-check-acknowledge-yes">
                        <Form.Check
                          type="checkbox"
                          id="hours_check_acknowledge_yes"
                          onChange={this.handleChange}
                          
                          name="hours_check_acknowledge_yes"
                          label={this.state.hours_check_acknowledge}
                        />

                      </Form.Group>
                    ) : (
                      <span></span>
                    )}
                  
                  <Form.Check
                    type="radio"
                    id="hours_check_no"
                    name="hours_check"
                    checked={this.state.hours_check === "No"}
                    onChange={this.handleChange}
                    value="No"
                    label="No"
                    // onChange={this.handleChange}
                  />
                  <Form.Check
                    type="radio"
                    id="hours_check_unknow"
                    name="hours_check"
                    label="I don't know"
                    checked={this.state.hours_check === "I don't know"}
                    onChange={this.handleChange}
                    value="I don't know"
                    // onChange={this.handleChange}
                  />
                </Form.Group>
                
                
                
                
                
                  <Form.Group>
                    <Form.Label>
                      {this.state.double_check_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>

                    <Form.Check
                      type="radio"
                      id="double_check_sure"
                      checked={this.state.double_check === "I am sure"}
                      onChange={this.handleChange}
                      value="I am sure"
                      name="double_check"
                      label="I'm sure"
                    />
                    <Form.Check
                      type="radio"
                      id="double_check_unsure"
                      name="double_check"
                      value="I want to speak to someone first"
                      checked={
                        this.state.double_check ===
                        "I want to speak to someone first"
                      }
                      onChange={this.handleChange}
                      label="I want to speak to someone first"
                    />
                  </Form.Group>
                  <Form.Group
                    controlId="contact_phone"
                    className={this.state.contact_phone_error}
                  >
                    <Form.Label
                      className={this.state.contact_phone_error + "_label"}
                    >
                      {this.state.contact_phone_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={this.handleChange}
                      value={this.state.contact_phone}
                      name="contact_phone"
                      className={this.state.new_campus_error + "_select"}
                    />
                  </Form.Group>

                  <div id="form_why" className={this.state.why_error}>
                    <label
                      for="why"
                      className={this.state.why_error + "_label form-label"}
                    >
                      {this.state.why_title} <span className="red"> *</span>
                    </label>
                    <Select
                      id="why"
                      value={this.state.why}
                      options={StaticData.add_concurrent_whyList}
                      onChange={this.handleChangeSelect.bind(this, "why")}
                      className={this.state.why_error + "_select"}
                      isClearable={true}
                    />
                  </div>
                 <br/>
    
                <div id="form_objective_info" className={this.state.objective_info_error}>
                  <label for="objective_info" className={this.state.objective_info_error +"_label form-label"}>
                  {this.state.objective_info_title}<span className="red"> *</span>
                  </label>
                  <textarea
                  className="form-control"
                  id="objective_info"
                  rows="5"
                  onChange={this.handleChange}
                  value={this.state.objective_info}
                  name="objective_info"
                  />
                 </div>
                 <br/>

                 <Form.Group className={this.state.acknowledge_check_error}>
                    <Form.Label className={this.state.acknowledge_check_error + "_label"}>
                      {this.state.acknowledge_check_title}{" "}
                      <span className="red"> *</span>
                    </Form.Label>

                    {StaticData.add_concurrent_acknowledgeList.map(acknowledge => (
                        <Form.Check 
                            type="checkbox"
                            id={acknowledge.value}
                            label={acknowledge.label}
                            name="acknowledge_check"
                            value={acknowledge.value}
                            onChange={this.handleCheck}
                        />
                    ))}
                  <br/>ALL checkboxes are required and must be checked by the student in order for the form to submit.
                 </Form.Group>
                

                 <div id="form_additional_info">
                    <label for="additional_info" className={"_label form-label"}>
                    {this.state.additional_info_title}
                    </label>
                    <textarea
                    className="form-control"
                    id="additional_info"
                    rows="5"
                    onChange={this.handleChange}
                    value={this.state.additional_info}
                    name="additional_info"
                    />
                </div>

                </div>
              

              <br />
              <br />
                <div id="changeMajorDisclaimer">
                <p>
                Students pursuing concurrent degrees (also known as a "double major") earn two distinct degrees and receive two diplomas. 
                Working with their academic advisors, students can create their own concurrent degree combinations. 
                Submission of this form constitutes a 'request' to add this concurrent degree.
                </p>
                <p>
                However, students should be advised that some degree combinations are not possible due to repetitive course offerings and overlapping curricula. 
                Furthermore, students pursuing a concurrent degree (double major) should be aware that they may incur any of the following:

                </p>
                <p>
                  <li>additional program fees.</li>
                  <li>additional graduation fees.</li>
                  <li>additional semesters to graduation (students cannot graduate if one of their concurrent degrees is incomplete).</li>
                  <li>additional cost due to lack of financial aid for the added semesters that may be necessary to complete the concurrent degree (double major).</li>
                  
                </p>
                </div>

              <br />
              <br />
              
                <Form
                  onSubmit={this.handleSubmit}
                  className="searchDegreeSearchBtn"
                >
                  <Button className="btn-asu" type="submit">
                    Submit Request
                  </Button>
                </Form>


              <br />
              <br />
              <br />
            </Container>

            {sessionStorage.getItem(serviceauth.SS_VIEW_AS) ? (
              <Container>
                <div className="error_message">
                  You are viewing as student{" "}
                  {sessionStorage.getItem(serviceauth.SS_VIEW_AS)} {". "}
                  <button onClick={this.handleSwitch}> Click </button> to change
                  back to {sessionStorage.getItem(serviceauth.SS_NAME)}
                </div>
              </Container>
            ) : null}

            <Button className="btn wait submitWait nodisplay">
              <FontAwesomeIcon icon={faSpinner} spin /> Submitting Request ...
            </Button>
            
            <Footer />
          </div>
        ) : null}

        <Button className="btn wait loadWait">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading ...
        </Button>

        <Button className="btn wait resubmitWait nodisplay">
          <FontAwesomeIcon icon={faSpinner} spin /> Submitting Request... Do Not Close Browser.
        </Button>
      </Container>
    );
  }
}

export default RequestForm;
