const StaticData = {
  
  major_change_sourceList: [
    { label: "Faculty Member", value: "Faculty Member" },
    { label: "Friend", value: "Friend" },
    { label: "Mentor", value: "Mentor" },
    { label: "Academic Advisor", value: "Academic Advisor" },
    { label: "me3 Quiz", value: "me3 Quiz" },
    { label: "Degree Search", value: "Degree Search" },
    {
      label: "Career and Professional Development Services",
      value: "Career and Professional Development Services",
    },
    {
      label: "Major and Career Exploration Course",
      value: "Major and Career Exploration Course",
    },
    { label: "Other", value: "Other" },
  ],

 add_minor_sourceList: [
    { label: "Faculty Member", value: "Faculty Member" },
    { label: "Friend", value: "Friend" },
    { label: "Mentor", value: "Mentor" },
    { label: "Academic Advisor", value: "Academic Advisor" },
    { label: "Degree Search", value: "Degree Search" },
    {
      label: "Career and Professional Development Services",
      value: "Career and Professional Development Services",
    },
    {
      label: "Major and Career Exploration Course",
      value: "Major and Career Exploration Course",
    },
    { label: "Other", value: "Other" },
  ],

  whyList: [
    { label: "eAdvisor:Twice Off-Track", 
      value: "eAdvisor:Twice Off-Track" },
    {
      label: "Current major no longer interests me",
      value: "Current major no longer interests me",
    },
    {
      label: "Current major too demanding",
      value: "Current major too demanding",
    },
    {
      label: "Limited job prospects for current major",
      value: "Limited job prospects for current major",
    },
    {
      label: "Less time to graduation for new major",
      value: "Less time to graduation for new major",
    },
    { label: "Decided on major", value: "Decided on major" },
    {
      label: "Prefer physical or online location of new major",
      value: "Prefer physical or online location of new major",
    },
    { label: "Other", value: "Other" },
  ],

  add_minor_whyList: [
    {
      label: "I have a career interest in this subject",
      value: "I have a career interest in this subject.",
    },
    {
      label: "This minor complements my major",
      value: "This minor complements my major",
    },
    {
      label: "I have an academic interest in this minor",
      value: "I have an academic interest in this minor",
    },
    { label: "Other", value: "Other" },
  ],

  add_cert_whyList: [
    {
      label: "I have a career interest in this subject",
      value: "I have a career interest in this subject",
    },
    {
      label: "This certificate complements my major",
      value: "This certificate complements my major",
    },
    {
      label: "I have an academic interest in this certificate",
      value: "I have an academic interest in this certificate",
    },
    { label: "Other", value: "Other" },
  ],

  add_concurrent_whyList: [
    {
      label: "Academic/personal interest in the field of study",
      value: "Academic/personal interest in the field of study",
    },
    {
      label: "I am \"ahead\" in my degree progression and wish to maximize my time at ASU",
      value: "I am \"ahead\" in my degree progression and wish to maximize my time at ASU",
    },
    {
      label: "Expand professional opportunities post-graduation",
      value: "Expand professional opportunities post-graduation",
    },
    {
      label: "I am preparing for a possible graduate (Master's, Doctorate) degree",
      value: "I am preparing for a possible graduate (Master's, Doctorate) degree",
    },
    {
      label: "The concurrent degree is easily attainable for me",
      value: "The concurrent degree is easily attainable for me",
    },
    { label: "Other", value: "Other" },
  ],

  add_concurrent_acknowledgeList: [
    {
      label: "I understand that concurrent degrees are awarded at the same time and that all requirements for both degrees must be satisfied before either degree can be conferred.",
      value: "I understand that concurrent degrees are awarded at the same time and that all requirements for both degrees must be satisfied before either degree can be conferred.",
    },
    {
      label: "I understand that admission criteria, policy for double-counting credits, and other requirements and rules are defined by the Colleges/Schools offering the degree programs.",
      value: "I understand that admission criteria, policy for double-counting credits, and other requirements and rules are defined by the Colleges/Schools offering the degree programs.",
    },
    {
      label: "I understand that financial aid and/or scholarship(s) limits apply and may not cover all semesters.",
      value: "I understand that financial aid and/or scholarship(s) limits apply and may not cover all semesters.",
    },
    {
      label: "I understand that I may need to invest more time and funds to complete concurrent degrees.",
      value: "I understand that I may need to invest more time and funds to complete concurrent degrees.",
    },
    {
      label: "I understand that both Colleges/Schools must approve my request to pursue a concurrent degree, and that I may be asked to supply additional information to support my request.",
      value: "I understand that both Colleges/Schools must approve my request to pursue a concurrent degree, and that I may be asked to supply additional information to support my request.",

    },
  ],

  locationList: [
    { value: "DTPHX", label: "Downtown Phoenix" },
    { value: "POLY", label: "Polytechnic" },
    { value: "TEMPE", label: "Tempe" },
    { value: "WEST", label: "West" },
    { value: "COCHISE", label: "ASU@Cochise" },
    { value: "CALHC", label: "ASU@Lake Havasu" },
    { value: "PIMA", label: "ASU@Pima" },
    { value: "CAC", label: "ASU@Pinal" },
    { value: "EAC", label: "ASU@The Gila Valley" },
    { value: "YAVAP", label: "ASU@Yavapai" },
    { value: "AWC", label: "ASU@Yuma" },
  ],

  gsList: [
    { value: "L", label: "L: Literacy and Critical Inquiry" },
    { value: "MA", label: "MA: Mathematical Studies" },
    { value: "CS", label: "CS: Computer/Statistics/Quantitative Applications" },
    { value: "HU", label: "HU: Humanities, Arts and Design" },
    { value: "SB", label: "SB:  Social-Behavioral Sciences" },
    { value: "SQ", label: "SQ: Natural Science - Quantitative" },
    { value: "SG", label: "SG: Natural Science - General" },
    { value: "C", label: "C: Cultural Diversity in the U.S." },
    { value: "G", label: "G: Global Awareness" },
    { value: "H", label: "H: Historical Awareness" },
  ],

  collegeList: [
    { value: "BA", label: "Business", acad_org: "CBA" },
    { value: "HI", label: "Design & the Arts Institute", acad_org: "CHI" },
    { value: "TE", label: "Education", acad_org: "CTE" },
    { value: "ES", label: "Engineering", acad_org: "CES" },
    { value: "FI", label: "Future of Innovation in Society", acad_org: "CFI" },
    { value: "TB", label: "Global Management", acad_org: "CTB" },
    { value: "GC", label: "Graduate College", acad_org: "CGC" },
    { value: "NH", label: "Health Solutions", acad_org: "CHL" },
    { value: "HO", label: "Honors", acad_org: "CHO" },
    { value: "LS", label: "Integrative Sciences & Arts", acad_org: "CLS" },
    { value: "CS", label: "Journalism", acad_org: "CCS" },
    { value: "LW", label: "Law", acad_org: "CLW" },
    { value: "LA", label: "Liberal Arts & Sciences", acad_org: "CLA" },
    { value: "AS", label: "New College", acad_org: "CAS" },
    { value: "NU", label: "Nursing & Health", acad_org: "CNU" },
    { value: "PP", label: "Public Service", acad_org: "CPP" },
    { value: "SU", label: "Sustainability", acad_org: "CSS" },
    { value: "UC", label: "University College", acad_org: "CUC" },
  ],

  levelList: [
    { value: "lowerdivision", label: "Lower division" },
    { value: "upperdivision", label: "Upper division" },
    { value: "undergrad", label: "Undergrad" },
    { value: "grad", label: "Graduate" },
    { value: "100-199", label: "100-199" },
    { value: "200-299", label: "200-299" },
    { value: "300-399", label: "300-399" },
    { value: "400-499", label: "400-499" },
  ],

  unitsList: [
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
    { id: "4", name: "4" },
    { id: "5", name: "5" },
    { id: "6", name: "6" },
    { id: "7", name: "7 or more" },
  ],

  daysList: [
    { id: "MON", name: "Monday" },
    { id: "TUES", name: "Tuesday" },
    { id: "WED", name: "Wednesday" },
    { id: "THURS", name: "Thursday" },
    { id: "FRI", name: "Friday" },
    { id: "SAT", name: "Saturday" },
    { id: "SUN", name: "Sunday" },
  ],

  adminList: [
    "guirongg",
    "cfwade",
    "tebernar",
    "ejulian",
    "anelso54",
    "agarriss",
    "dalbasha",
    "jglawson",
    "sthorpe",
    "elesaca",
    "sdstephe",
    "jmaleric",
    "tpeter51",
    "sbotner",
    "mariac",
    "kmlarso6",
    "blandish",
    'blloyd',
    'klkear',

  ],
};

export default StaticData;
