import React, { Component } from "react";
import "./css/App.css";
import Container from "react-bootstrap/Container";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";

class Confirmation extends Component {

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />

        <Menu />
        <Container className="changeMajorForm">
          <div className="pageTitle">
            Your Change of Major Request Form has been submitted.
          </div>
        </Container>

        <Container className="changeMajorForm center">
          <br />
          <p>
            You will receive a confirmation email with your case number shortly.
            Once you do, you will be able to track this case in the{" "}
            <a
              href="https://webapp4.asu.edu/myasu/student/servicecenter"
              target="_blank" rel="noopener noreferrer" 
            >
              Service Center
            </a>{" "}
            on My ASU.
          </p>

          <p>
            <a href="https://webapp4.asu.edu/uachieve/" target="_blank" rel="noopener noreferrer" >
              Click here to see your ‘What if Audit.’
            </a>{" "}
            A ‘What if Audit’ is a Degree Audit Report illustrating how your
            completed courses apply towards your new major/degree. However,
            please know that the college and major you may be changing to will
            review your credits and may need to update your final graduation
            degree audit.
          </p>

          <p>
            You will be contacted by a representative of the major/college you
            wish to change to within two business days, who may want to schedule
            an advising appointment. Please check back to this case for
            additional instructions.
          </p>

          <br />
          <br />
          <br />
          <br />
        </Container>

        <Footer />
      </Container>
    );
  }
}

export default Confirmation;
