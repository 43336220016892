import React from "react";
import RequestForm from "./RequestForm";
import AddMinorRequestForm from "./AddMinorRequestForm";
import AddCertRequestForm from "./AddCertRequestForm";
import AddConcurrentRequestForm from "./AddConcurrentRequestForm";
import Confirmation from "./Confirmation";
import ConfirmationMinors from "./ConfirmationMinors";
import ConfirmationCerts from "./ConfirmationCerts";
import ConfirmationConcurrent from "./ConfirmationConcurrent";
import Notice from "./Notice";
import Ineligible from "./Ineligible"
import Pending from "./Pending"
import IneligibleOther from "./IneligibleOther"

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";



const App = {
  App() {
    
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={RequestForm} /> 
          <Route path="/request" component={RequestForm} />
          <Route path="/minors" component={AddMinorRequestForm} />
          <Route path="/certs" component={AddCertRequestForm} />
          <Route path="/concurrent" component={AddConcurrentRequestForm} />
          <Route path="/confirmation" component={Confirmation} />
          <Route path="/confirmationMinors" component={ConfirmationMinors} />
          <Route path="/confirmationCerts" component={ConfirmationCerts} />
          <Route path="/confirmationConcurrent" component={ConfirmationConcurrent} />
          <Route path="/notice" component={Notice} /> 
          <Route path="/ineligible" component={Ineligible} /> 
          <Route path="/pending" component={Pending} /> 
          <Route path="/ineligibleOther" component={IneligibleOther} /> 
        </Switch>
      </Router>
    );
  },
};



export default App;
