import React, { Component } from "react";
import "./css/App.css";

import Container from "react-bootstrap/Container";
import Menu from "./components/Menu";
import Header from "./components/Header";
import Footer from "./components/Footer";

class Ineligible extends Component {

  componentDidMount() {
      setTimeout(() => { 
        window.location.href = "https://webapp4.asu.edu/admissionresponse/confirmmajor";
      }, 5000)
    
  }

  render() {
    return (
      <Container fluid={true} className="p-0">
        <Header />

        <Menu />
        <Container className="changeMajorForm">
          <div className="pageTitle">
           Please wait while you are being redirected......
          </div>
        </Container>

        <Container className="changeMajorForm">
          <br />
          <p>
          As a newly admitted student, your change of major request must be reviewed by Admission Services.  You will be redirected to their request form in a moment.

          </p>
          <p>
          If you don’t wish to wait, click <a href="https://webapp4.asu.edu/admissionresponse/confirmmajor">here</a>
          </p>
          <br />
          <br />
          <br />
        </Container>

        <Footer />
      </Container>
    );
  }
}

export default Ineligible;
